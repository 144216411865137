import { graphql, PageProps } from 'gatsby';
import React from 'react';
import Item from '../components/item';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Denero from 'dinero.js';

interface Category {
  category: string;
  items: Item[];
}

interface Item {
  name: string;
  description: string;
  amount: number;
  currencyCode: Denero.Currency;
}

interface Query {
  allPricesJson: {
    nodes: Category[];
  };
}

const Offer: React.FC<PageProps<Query>> = (props) => {
  const nodes = props.data.allPricesJson.nodes;
  const deals = nodes
    .filter((items) => items.category === `Deals`)
    .map((deals) => deals.items)
    .reduce((item) => item)
    .map((item) => <Item {...item} />);

  const extras = nodes
    .filter((items) => items.category === `Extras`)
    .map((deals) => deals.items)
    .reduce((item) => item)
    .map((item) => <Item {...item} />);

  const snacks = nodes
    .filter((items) => items.category === `Snacks`)
    .map((deals) => deals.items)
    .reduce((item) => item)
    .map((item) => <Item {...item} />);

  const upgrades = nodes
    .filter((items) => items.category === `Upgrades`)
    .map((deals) => deals.items)
    .reduce((item) => item)
    .map((item) => <Item {...item} />);

  const nerf = nodes
    .filter((items) => items.category === `Nerf Tag & Laser Tag`)
    .map((deals) => deals.items)
    .reduce((item) => item)
    .map((item) => <Item {...item} />);

  return (
    <Layout>
      <SEO title="Prices" />
      <h3>Price List</h3>
      <h4>Deals</h4>
      <table>
        <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead>
        <tbody>{deals}</tbody>
      </table>
      <h4>Extras</h4>
      <table>
        <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead>
        <tbody>{extras}</tbody>
      </table>
      <h4>Snacks</h4>
      <table>
        <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead>
        <tbody>{snacks}</tbody>
      </table>
      <h4>Upgrades</h4>
      <table>
        <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead>
        <tbody>{upgrades}</tbody>
      </table>
      <h4>Nerf Tag & Laser Tag</h4>
      <table>
        <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead>
        <tbody>{nerf}</tbody>
      </table>
    </Layout>
  );
};

export default Offer;

export const query = graphql`
  {
    allPricesJson {
      nodes {
        category
        items {
          amount
          currencyCode
          description
          name
        }
      }
    }
  }
`;
