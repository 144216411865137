import React from 'react';
import Denero from 'dinero.js';

interface ItemProps {
  name: string;
  description: string;
  amount: number;
  currencyCode: Denero.Currency;
}

const Item: React.FC<ItemProps> = (props) => (
  <tr>
    <td>{props.name}</td>
    <td>{props.description}</td>
    <td>
      {Denero({
        amount: props.amount,
        currency: props.currencyCode,
      }).toFormat()}
    </td>
  </tr>
);

export default Item;
